<template>
  <ChannelContainer />
</template>

<script>
import ChannelContainer from "@/components/pages/channel/ChannelContainer.vue";

export default {
  name: "ChannelPage",
  components: {
    ChannelContainer
  }
};
</script>
