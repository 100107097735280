<template>
  <div class="o2-container">
    <PageTitle text="Channel Info" />
    <Loader :isLoading="isLoading" />
    <Channel :channel="channel" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Loader from "@/components/layout/Loader";
import PageTitle from "@/components/layout/PageTitle";

import Channel from "./Channel";

export default {
  name: "ChannelContainer",
  components: {
    Loader,
    PageTitle,
    Channel
  },
  computed: {
    ...mapGetters(["isLoading", "channel"])
  },
  methods: {
    ...mapActions(["setChannel"])
  },
  mounted() {
    if (Object.keys(this.channel).length === 0) {
      this.setChannel();
    }
  }
};
</script>
