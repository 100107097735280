<template>
  <div class="row">
    <div class="col-xl-4 col-md-6 col-xs-12 m-auto">
      <div class="o2-channel">
        <h5>{{ channelUrl }}</h5>
        <a :href="channelUrl" target="_blank" :title="title">
          <img :src="url" :alt="channelUrl" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Channel",
  props: {
    channel: Object
  },
  computed: {
    title() {
      if (Object.keys(this.channel).length > 0) {
        return this.channel.snippet.title;
      }
      return null;
    },
    url() {
      if (Object.keys(this.channel).length > 0) {
        return this.channel.snippet.thumbnails.high.url;
      }
      return null;
    },
    channelUrl() {
      if (Object.keys(this.channel).length > 0) {
        return "https://www.youtube.com/" + this.channel.snippet.customUrl;
      }
      return null;
    }
  }
};
</script>


<style scoped>
div.o2-channel {
  position: relative;
  margin: 1rem auto;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
}

div.o2-channel:hover h5 {
  opacity: 0;
}

div.o2-channel:hover small {
  opacity: 1;
}

div.o2-channel h5 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1rem;
  opacity: 1;
  transition: all 0.2s linear;
}

@media only screen and (max-width: 600px) {
  div.o2-channel h5 {
    font-size: 0.8rem;
  }
}

div.o2-channel small {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  opacity: 0;
  transition: all 0.2s linear;
}

img {
  width: 100%;
  border-radius: 0.5rem;
}
</style>